import React from "react";
import { render } from "react-dom";
import AppLauncher from "./components/AppLauncher";

const initAppLauncher = () => {
  const node = document.getElementById("app-launcher");

  if (node) {
    // @ts-ignore
    window.appLauncherLoaded = true;

    render(<AppLauncher />, node);
  }
};

if (["complete", "interactive"].includes(document.readyState)) {
  initAppLauncher();
} else {
  document.addEventListener("DOMContentLoaded", initAppLauncher);
}

// @ts-ignore
window.initAppLauncher = initAppLauncher;

//@ts-ignore
window.module = {}; // obligatory line for now (TODO remove)
