import React, { useState } from "react";
import {
  AppLauncher as AppLauncherBase,
  Button,
  AppLauncherExpandableSection,
  AppLauncherTile,
  Search,
  IconSettings,
} from "@salesforce/design-system-react";
import { groupBy, map } from "lodash";

const AppLauncher = () => {
  const node = document.getElementById("app-launcher");
  const [search, setSearch] = useState("");

  const searchHeader = (
    <Search
      onChange={(event: any) => {
        setSearch(event.target.value);
      }}
      placeholder="Find an app"
      assistiveText={{ label: "Find an app" }}
    />
  );

  return (
    <IconSettings iconPath={node?.getAttribute("data-icon-path")}>
      <AppLauncherBase
        search={searchHeader}
        title="Perf Apps"
        triggerName={
          <a
            href={node?.getAttribute("data-base-url") || location.origin}
            style={{
              position: "relative",
              top: 2,
              color: "black",
              textDecoration: "none",
            }}
          >
            {node?.getAttribute("data-title")}
          </a>
        }
        modalHeaderButton={
          <Button
            label="Contact Perf Team"
            onClick={() =>
              window.open("https://app.slack.com/client/T17CFC1D2/C0193HWB3N1")
            }
          />
        }
      >
        {map(
          groupBy(require("./apps.json"), "group"),
          (items: any[], title: string) => (
            <AppLauncherExpandableSection title={title} key={title}>
              {items.map((item, i) => (
                <AppLauncherTile
                  key={i}
                  description={item.description}
                  href={item.url}
                  iconNode={
                    <img
                      src={
                        item.icon ||
                        "https://www.salesforce.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg"
                      }
                      width={50}
                      height={50}
                    />
                  }
                  search={search}
                  title={item.title}
                />
              ))}
            </AppLauncherExpandableSection>
          )
        )}

        {/* <hr /> */}
        {/* <AppLauncherExpandableSection title="All Items">
              <AppLauncherLink search={search}>Accounts</AppLauncherLink>
              <AppLauncherLink search={search}>Announcements</AppLauncherLink>
              <AppLauncherLink search={search}>Approvals</AppLauncherLink>
              <AppLauncherLink search={search}>Campaigns</AppLauncherLink>
              <AppLauncherLink search={search}>Cases</AppLauncherLink>
              <AppLauncherLink search={search}>Coaching</AppLauncherLink>
              <AppLauncherLink search={search}>Contacts</AppLauncherLink>
            </AppLauncherExpandableSection> */}
      </AppLauncherBase>
    </IconSettings>
  );
};

export default AppLauncher;
